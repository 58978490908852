@keyframes anime {
    0% {
        background-color: gray;
    }

    50% {
        background-color: green;
    }

    100% {
        background-color: purple;
    }

}

.bubbles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.bubble {
    animation-name: anime;
    width: 20px;
    height: 20px;
    margin-right: 3px;
    background-color: gray;
    border-radius: 50%;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
}