.comment-field {
    width: 300px;
    border-bottom: 0.5px rgb(239, 239, 239) solid;
    height: 100px;
    word-wrap: break-word;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--medium);
}