.page-container {}

.page-header .first {
    font-size: 26px;
    font-weight: 600;
}

.page-header .second {
    font-size: 18px;
    font-weight: 600;
}

.table-container-outer {
    width: 90vw;
    height: 110vh;
    margin-bottom: var(--large);
    -webkit-box-shadow: 0 0 5px 2px #dbdbdb;
    -moz-box-shadow: 0 0 5px 2px #dbdbdb;
    box-shadow: 0 0 5px 2px #dbdbdb;
    padding-right: var(--large);
}

.page-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    padding: 0 var(--large);
}