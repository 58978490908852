:root {
    --small: 8px;
    --tiny: 6px;
    --medium: 12px;
    --large: 16px;
    --huge: 20px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    zoom: 90%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}


body {
    margin: 0;
    font-family: 'Diodrum', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


.br {
    background-color: red;
}

.bw {
    background-color: white;
}

.width-90 {
    width: 90%;
}

.width-100 {
    width: 100%;
}

.width-70 {
    width: 70%;
}

.width-60 {
    width: 60%;
}

.width-fit-content {
    width: fit-content;
}

.h-100v {
    height: 100vh;
}

.h-70v {
    height: 70vh;
}

.huge-font {
    font-size: 24px;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
}

.justify-flex-start {
    justify-content: flex-start;
}

.p-10 {
    padding: 10px;
}

.align-center {
    align-items: center;
}

.align-left {
    align-items: left;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.curved-small {
    border-radius: var(--small);
}

.curved-large {
    border-radius: var(--large);
}

.round-image {
    border-radius: 100%;
    object-fit: fill;
}

.padding-small {
    padding: var(--small);
}

.padding-large {
    padding: var(--large);
}

.float-effect {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.background-light-gray {
    background: #fafafa;
}

.line-height-2 {
    /* line-height: 2; */
}

.p-x-medium {
    padding: 0 var(--medium);
}

.p-y-medium {
    padding: var(--medium) 0;
}


.m-b-huge {
    margin-bottom: var(--huge);
}

.m-b-large {
    margin-bottom: var(--large);
}

.m-b-medium {
    margin-bottom: var(--medium);
}

.m-t-small {
    margin-top: var(--small);
}

.m-t-huge {
    margin-top: var(--huge);
}

.m-t-large {
    margin-top: var(--large);
}

.m-t-medium {
    margin-top: var(--medium);
}

.m-l-small {
    margin-left: var(--small);
}

.m-l-huge {
    margin-left: var(--huge);
}

.m-l-large {
    margin-left: var(--large);
}

.m-l-medium {
    margin-left: var(--medium);
}

.m-b-small {
    margin-bottom: var(--small);
}

.m-large {
    margin: var(--large);
}

.m-small {
    margin: var(--small);
}

.red-text {
    color: #E4001C;
}

.gray-text {
    color: #969696;
}

.lgray-text {
    color: #969696;
}

.blue-text {
    color: #40A9FF;
}

.black-text {
    color: black;
}

.list-style-none {
    list-style-type: none;
}

:root {
    --small: 8px;
    --tiny: 6px;
    --medium: 12px;
    --large: 16px;
    --huge: 20px;
}

.br {
    background-color: red;
}

.bw {
    background-color: white;
}

.width-90 {
    width: 90%;
}

.width-100 {
    width: 100%;
}

.width-70 {
    width: 70%;
}

.width-60 {
    width: 60%;
}

.h-100v {
    height: 100vh;
}

.huge-font {
    font-size: 24px;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
}

.justify-flex-start {
    justify-content: flex-start;
}

.p-10 {
    padding: 10px;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.curved-small {
    border-radius: var(--small);
}

.curved-large {
    border-radius: var(--large);
}

.round-image {
    border-radius: 100%;
    object-fit: fill;
}

.padding-small {
    padding: var(--small);
}

.padding-large {
    padding: var(--large);
}

.float-effect {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.background-light-gray {
    background: #fafafa;
}

.line-height-2 {
    /* line-height: 2; */
}

.p-x-medium {
    padding: 0 var(--medium);
}

.p-y-medium {
    padding: var(--medium) 0;
}


.m-b-huge {
    margin-bottom: var(--huge);
}

.m-b-large {
    margin-bottom: var(--large);
}

.m-b-medium {
    margin-bottom: var(--medium);
}

.m-t-small {
    margin-top: var(--small);
}

.m-t-huge {
    margin-top: var(--huge);
}

.m-t-large {
    margin-top: var(--large);
}

.m-t-medium {
    margin-top: var(--medium);
}

.m-b-small {
    margin-bottom: var(--small);
}

.m-large {
    margin: var(--large);
}

.m-small {
    margin: var(--small);
}

.medium-heading {
    font-size: 18px;
    font-weight: 600;
}

::-webkit-scrollbar {
    width: 13px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f9f9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8BDCF9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #71d9ff;
}

body::-webkit-scrollbar {
    width: 13px;
    height: 8px;
}

/* Track */
body::-webkit-scrollbar-track {
    background: #f9f9f9;
}

/* Handle */
body::-webkit-scrollbar-thumb {
    background: rgb(248, 196, 98);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(248, 176, 41);
}

.text-decoration-none {
    text-decoration: none;
}

.transparent-text {
    color: transparent;
}

.bold {
    font-weight: 500;
}