.sidebar {
    width: 270px;
    padding-left: 1.9rem;
}

.sidebar div .side-text {
    font-weight: 600;
}

.sidebar div .main-text {
    font-weight: 600;
    font-size: 32px;
}

.sidebar div {
    margin-bottom: var(--huge);
}

.sidebar-checkbox-container {
    width: fit-content;
    margin-left: calc(-1 * var(--medium));
}

.sidebar-checkbox-container :first-child {
    margin-left: var(--small);
}