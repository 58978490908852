.header {
    position: sticky;
    top: 0;
}

.header,
.header .first,
.header .second {
    z-index: 9 !important;
    /* position: sticky;
    top: 0; */
}

.header .first {
    /* padding: 38px 28px;
    padding-bottom: 18px; */
    padding: 1rem 2rem;
    padding-bottom: 0.5rem;
    background-color: white;
}

.header .first .left h1 {
    font-size: 27px;
    margin-right: 8px;
}

.header .first .left span {
    font-size: 27px;
}

.header .second {
    background-color: #EBEBEB;

}

.nav {
    padding-left: 1.3rem;
    height: 49px;
}

.nav li {
    padding: 10px;
}

.nav li a {
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.nav li a:hover {
    color: #009CDE;
}

.nav li a.active {
    color: #009CDE;
}

.dupont-logo {
    height: 35px;
}